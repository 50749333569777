import React, {useCallback, useMemo} from 'react';
import {
  PolygonIcon,
  EthereumNetworkIcon,
  ZilliqaIcon,
  CurrencyIcon,
  CurrencyIconProps,
} from '../../icons';
import {Typography} from '../../typography';
import {Box} from '../../box';
import {Pressable} from '../../pressable';
import {UnstyledHtmlAnchor} from '../../link-renderer';
import {ArrowLinkIcon} from '../../icons/arrow-link';
import {NFTState} from '../types';
import {formatTokenId} from '../utils';

interface CardBottomDetailsProps {
  price?: string | null;
  currencySymbol?: CurrencyIconProps['name'] | null;
  network?: 'polygon' | 'ethereum' | 'zilliqa' | null;
  state?: NFTState;
  totalSupply?: string | null;
  openseaUrl?: string;
  ownedQty?: number;
  tokenId?: string;
  multiCopy?: boolean;
  isHide?: boolean;
  hideCurrency?: boolean;
}
export const CardBottomDetails: React.FC<CardBottomDetailsProps> = ({
  price,
  currencySymbol,
  network,
  state,
  totalSupply,
  openseaUrl,
  ownedQty,
  tokenId,
  multiCopy,
  isHide = false,
  hideCurrency,
}) => {
  const renderChainIcon = useCallback(() => {
    switch (network) {
      case 'polygon':
        return <PolygonIcon size={16} color="textDefault" />;
      case 'ethereum':
        return <EthereumNetworkIcon size={16} color="textDefault" />;
      case 'zilliqa':
        return <ZilliqaIcon size={16} color="textDefault" />;
      default:
        return <PolygonIcon size={16} color="textDefault" />;
    }
  }, [network]);

  const ownedDetails = useMemo(
    () => (
      <Typography color="textDefault" textStyle="h2">
        {multiCopy ? `x${ownedQty}` : formatTokenId(tokenId)}
      </Typography>
    ),
    [multiCopy, ownedQty, tokenId],
  );

  const availableDetails = useMemo(() => {
    return (
      <>
        <Box>
          <Typography color="textDefault" textStyle="s">
            Edition of {totalSupply || 1}
          </Typography>
          {!hideCurrency && (
            <Box flexDirection="row">
              {price && currencySymbol && Number(price) ? (
                <>
                  <CurrencyIcon
                    name={currencySymbol}
                    size={18}
                    color="textDefault"
                  />
                  <Typography textStyle="s" color="textDefault">
                    {' '}
                    {price}{' '}
                  </Typography>
                  <Typography color="textLow">{currencySymbol}</Typography>
                </>
              ) : null}
              {price && currencySymbol && !Number(price) ? (
                <Typography textStyle="s" color="textDefault">
                  Free
                </Typography>
              ) : null}
            </Box>
          )}
        </Box>
      </>
    );
  }, [currencySymbol, price, totalSupply]);

  const membershipDetails = useMemo(() => {
    return (
      <Box>
        <Typography color="textDefault" textStyle="s">
          Edition of {totalSupply || 1}
        </Typography>
        <Box flexDirection="row">
          <Typography textStyle="s" color="textDefault">
            Free For Members
          </Typography>
        </Box>
      </Box>
    );
  }, [currencySymbol, price, totalSupply]);

  const soldOutDetails = useMemo(() => {
    return (
      <Box>
        <Typography color="textDefault" textStyle="s">
          SOLD OUT
        </Typography>
        {openseaUrl ? (
          <Pressable
            onPress={(e) => {
              e.stopPropagation();
            }}
          >
            <UnstyledHtmlAnchor
              href={openseaUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Box flexDirection="row" alignItems="center">
                <Typography
                  color="textLow"
                  textStyle="s"
                  textDecoration="underline"
                  style={{marginRight: '5px'}}
                >
                  View on OpenSea
                </Typography>
                <ArrowLinkIcon color="textLow" />
              </Box>
            </UnstyledHtmlAnchor>
          </Pressable>
        ) : null}
      </Box>
    );
  }, [openseaUrl]);

  const comingSoonOrBlindDropDetails = useMemo(() => {
    let editions = `${
      Number(totalSupply) > 0 ? `Edition of ${totalSupply}` : '-'
    }`;
    if (state === 'traxxStemz') {
      editions = 'Edition of 3030';
    } else if (state === 'emilg') {
      editions = 'Edition of 25';
    }
    return (
      <Box>
        <Typography color="textDefault" textStyle="s">
          Coming soon
        </Typography>
        <Typography color="textLow" textStyle="s">
          {editions}
        </Typography>
      </Box>
    );
  }, [state, totalSupply]);

  const traxxStemzDetails = useMemo(() => {
    return (
      <Box>
        <Typography color="textDefault" textStyle="s">
          Edition of 3030
        </Typography>
        <Box flexDirection="row">
          {price && currencySymbol && Number(price) ? (
            <>
              <CurrencyIcon
                name={currencySymbol}
                size={18}
                color="textDefault"
              />
              <Typography textStyle="s" color="textDefault">
                {' '}
                {price}{' '}
              </Typography>
              <Typography color="textLow">{currencySymbol}</Typography>
            </>
          ) : null}
          {price && currencySymbol && !Number(price) ? (
            <Typography textStyle="s" color="textDefault">
              Free
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }, [currencySymbol, price]);

  const emilGDetails = useMemo(() => {
    return (
      <Box>
        <Typography color="textDefault" textStyle="s">
          Edition of 25
        </Typography>
        <Box flexDirection="row">
          {price && currencySymbol && Number(price) ? (
            <>
              <CurrencyIcon
                name={currencySymbol}
                size={18}
                color="textDefault"
              />
              <Typography textStyle="s" color="textDefault">
                {' '}
                {price}{' '}
              </Typography>
              <Typography color="textLow">{currencySymbol}</Typography>
            </>
          ) : null}
          {price && currencySymbol && !Number(price) ? (
            <Typography textStyle="s" color="textDefault">
              Free
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }, [currencySymbol, price]);

  const renderedDetails = useMemo(() => {
    switch (true) {
      case !!ownedQty:
        return ownedDetails;
      case state === 'available':
        return availableDetails;
      case state === 'sold out':
        return soldOutDetails;
      case state === 'traxxStemz':
        return traxxStemzDetails;
      case state === 'emilg':
        return emilGDetails;
      case state === 'membership':
        return membershipDetails;
      default:
        return comingSoonOrBlindDropDetails;
    }
  }, [
    availableDetails,
    comingSoonOrBlindDropDetails,
    ownedDetails,
    ownedQty,
    soldOutDetails,
    state,
    traxxStemzDetails,
    emilGDetails,
  ]);

  if (isHide) return <></>;

  return (
    <Box
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="space-between"
    >
      {renderedDetails}
      <Box mb="xs">{renderChainIcon()}</Box>
    </Box>
  );
};
